<template lang="html">
  <div>
    <div v-if="status === 'close'" style="height:calc(100vh - 100px)" class="d-flex justify-center align-center fill-height">
      <div class="d-flex flex-column align-center">
        <v-img id="sign" src="@/assets/tutup.png"></v-img>
        <div v-if="keterangan == ''">
          <h2 class="text-h2">{{formatedTimeLeft}}</h2>
          <p>waktu pengisian {{ siteSettings.waktu_buka}} - {{siteSettings.waktu_tutup}}</p>
        </div>
        <div v-else>
          <br>
          <p> {{ keterangan }} </p>
        </div>
      </div>
    </div>
    <div v-else class="d-flex align-center">
      <p class="red--text text--lighten-1 font-weight-light" v-if="formatedTimeLeft">Sisa waktu pengisian {{formatedTimeLeft}}</p>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'
export default {
  props: {
    keterangan:String,
    status:String
  },
  data(){
    return{
      timeLeft:null,
      timer:null,
      // status:'',
      siteSettings:{},
    }
  },

  computed:{
    formatedTimeLeft(){
      let h = 60*60*1000
      let m = 60*1000
      let s = 1000
      if(this.timeLeft && this.timeLeft > 0){
        let hour = Math.floor(this.timeLeft/h)
        let minute = Math.floor((this.timeLeft % h) /m)
        let second = Math.floor((this.timeLeft % m) /s)
        return this.addZero(hour) +':'+ this.addZero(minute) +':'+ this.addZero(second)
      }
      return ''
    },
  },

  methods:{
    addZero(val){return val >= 10 ? val : '0'+val}
  },

  mounted(){
    this.siteSettings = store.state.settings.site
    let open = moment(this.siteSettings.waktu_buka,'HH:mm')
    let close = moment(this.siteSettings.waktu_tutup,'HH:mm')

    let closeNextDay = moment(this.siteSettings.waktu_tutup,'HH:mm').add(1,'d')

    // untuk test only
    // open = moment('06:55','HH:mm')
    // close = moment('06:52','HH:mm')

    // let OpenOrNot = () => {
    //   if ( ( (moment() -  open) < 0 ) && (moment() - close > 0) ){
    //     this.status='close'
    //     let temp = open-moment()
    //     this.timeLeft = temp
    //   } else {
    //     this.status='open'
    //     let temp = close - moment()
    //     if(temp > 0){
    //       this.timeLeft = temp
    //     } else {
    //       this.timeLeft = closeNextDay - moment()
    //     }
    //   }
    //   this.$emit('status', this.status)
    // }

    // OpenOrNot()
    // var store_ = store.state.user
    // store_.open = this.status
    // this.timer = setInterval( OpenOrNot,1000)
    // this.$emit('keterangan', this.keterangan)
  },

  destroyed(){
    clearInterval(this.timer)
  }
}
</script>

<style lang="css" scoped>
  #sign{
    transform-origin: top center;
    transform: rotate(20deg);
    animation: moving-sign 3s infinite;
  }

  @keyframes moving-sign{
    50% { transform: rotate(-20deg);}
    100% { transform: rotate(20deg);}
  }
</style>
