<template lang="html">
  <v-container fluid>
    <lock-page :status="OpenClose" :keterangan="'Menu belum dibuka. Tunggu informasi selanjutnya, terima kasih.'"></lock-page>
    <v-sheet v-if="loadingBtn.dataPegawai && OpenClose == 'open'">
      <v-row v-for="i in [1,2]" :key="i">
        <v-skeleton-loader
        class="mx-auto mt-5"
        width="90%"
        type="card"
        ></v-skeleton-loader>
      </v-row>
    </v-sheet>
    <v-row v-if="showDataASNCerdas && OpenClose == 'open'">
      <v-col cols="12">
        <base-material-card
        color="green lighten-1"
        icon="mdi-check-all"
        title="Hasil Rating Kinerja Pegawai ASN Teladan"
        >
        <v-simple-table>
          <thead>
            <tr>
              <th>No</th>
              <th class="text-center" width="25%">NIP - Nama</th>
              <th>Jabatan</th>
              <th>Penilai (Orang)</th>
              <th>Rating</th>
              <th>Nilai Rating</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(peg,i) in asn_cerdas" :key="i">
              <td>{{ incrementIndex(i) }}</td>
              <td>
                <div class="ma-3 justify-center align-center text-center">
                  <v-avatar class="rounded-circle" tile size="120"> <v-img :src="peg.foto"></v-img></v-avatar>
                  <h3>{{peg.nama}}</h3>
                  <label style="font-size:14px">{{peg.nip}}</label>
                </div>
              </td>
              <td>{{peg.jabatan}}</td>
              <td>{{peg.total_penilai}}</td>
              <td> <star-rating :rating="parseFloat(peg.avg_rating)" :star-style="starStyle"></star-rating> </td>
              <td>{{peg.nilai_rating}}</td>
              <td>
                <div class="justify-center align-center text-center">
                <v-btn
                  color="primary"
                  small
                  class="mr-0"
                  @click="openDialog(peg.nip,i)"
                  :disabled=peg.submit_asn_teladan
                  :loading="loadingEl == i"
                  >
                    <span><v-icon>mdi-pencil-box</v-icon> Ulas Survey</span>
                  </v-btn>

                  <!-- <v-btn
                  color="orange"
                  small
                  class="mt-3"
                  @click="openDetail(peg.nip,i)"
                  :loading="loadingEl == i"
                  >
                    <span><v-icon>mdi-account-box-outline</v-icon> Detail </span>
                  </v-btn> -->
                  </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        </base-material-card>
      </v-col>
    </v-row>
    <div v-else>
      <v-row justify="center" v-if="OpenClose == 'open'">
        <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
          <v-alert
          color="blue-grey"
          dark
          dense
          icon="mdi-account-group"
          prominent
        >
          Belum ada data kandidat yang harus diulas
        </v-alert>
        </v-col>
      </v-row>
      <v-row class="mt-5 mb-5" v-if="OpenClose == 'open'">
        <v-col align="center">
          <v-btn color="primary" :href=" baseURL + 'survey/pilih-kandidat'">Pilih Kandidat dan Isi Semua Rating Kandidat Untuk Penilaian</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialogSurveyReferensiASNCerdas">
      <SurveyReferensiDetail :dataPegawai="dataPegawai" @onClose="(x)=>{dialogSurveyReferensiASNCerdas=x}" @onSubmitted="getData()"/>
    </v-dialog>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
// import SistemKerjaService from '@/services/SistemKerjaService'
import SKPService from '@/services/SKPService'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
// import FormIsiEkspektasiPerilaku from './FormIsiEkspektasiPerilaku.vue'
import {local} from '@/store/local'
import { mapState } from 'vuex'
// import _ from 'lodash'
import axios from 'axios'
// import SurveyReferensiService from '@/services/SurveyReferensiService'
import SurveyReferensiDetail from './SurveyReferensiDetail'
import StarRating from 'vue-dynamic-star-rating'
import LockPage from '@/components/common/LockPage'

export default {
  components:{
    SurveyReferensiDetail,
    StarRating,
    LockPage
  },

  data(){
    return{
      ratingDynamic: 4.7,
      starStyle: {
          fullStarColor: '#ed8a19',
          emptyStarColor: '#737373',
          starWidth: 30,
          starHeight: 30
      },
      valid:true,
      title:'Ulas Kandidat ASN Teladan Di Lingkungan Pemerintah Kota Bandung',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        add:false,
        del:false,
        upd:false,
        dataPegawai:false
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules,
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter'
      },
      dialogTambahAnggotaPokja: false,
      dataPegawaiBawahan: null,
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      dataPegawaiBaru: [],
      listPegawaiSKPD: [],
      modelListPegawaiSKPD: [],
      avatarImages:{},
      kode_skpd: null,
      pegawai: [],
      disabled: true,
      rating: 0,
      dataPegawai:{},
      dialogSurveyReferensiASNCerdas:false,
      showDataASNCerdas: false,
      asn_cerdas: [],
      belum_memilih: false,
      OpenClose:''
    }
  },
  created(){
    // this.year = new Date().getFullYear()-1
    // this.triwulan = _g.getTriwulan().toString()
    // var kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
    // var base_url = window.location.origin
    // // var role = this.current.role
    // // var username = this.current.username
    // if(kode_skpd != '4.03.01'){
    //   store.commit('snackbar/setSnack',{message:'Maaf, anda tidak bisa mengakses halaman Sistem Kerja.', color:'warning'})
    //   let confirmAction = confirm("Maaf, anda tidak bisa mengakses halaman Sistem Kerja.");
    //   if (confirmAction) {
    //     window.location.href = base_url + '/#/dashboard'
    //   } else {
    //     window.location.href = base_url + '/#/dashboard'
    //   }
    // }
    // // this.updateTable()
  },

  computed:{
    ...mapState({
      username: state=> state.user.current.username,
      user: state=> state.user.dataPegawai,
      current: state=> state.user.current
    }),
    baseURL: function () {
        let url = window.location.origin
        let x = "/#/"
        return url.concat(x)
    }
  },

  mounted(){
    let kode_skpd = this.kode_skpd ? this.kode_skpd : local.getLocal("kolok")
    this.getDataPegawaiSKPD(kode_skpd)
    this.getDataReviewerSurveyReferensiASN()
    this.getData()
  },

  methods:{
    incrementIndex(key) {
        return key + 1;
    },
    getData(){
      this.loadingBtn.dataPegawai = true
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_add_struktur_pokja = base_url_api + 'v1/get-hasil-rating-asn-teladan'
      axios.post(url_add_struktur_pokja, {
        nip: current.username,
      }).then(response => {
        let res = response.data
        this.OpenClose = res.open_close
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          if(this.belum_memilih == false){
            this.asn_cerdas = response.data.data
          }else{
            this.asn_cerdas = []
          }
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
        if(this.asn_cerdas.length != 0){
          this.showDataASNCerdas = true
        }else{
          this.showDataASNCerdas = false
          store.commit('snackbar/setSnack',{message: 'Belum ada data kandidat, silahkan pilih dan isi penilaian ratingnya. Terima kasih!', color:'warning'})
        }
      }).catch(err => {
        var error = err.response.data.message
        this.showDataASNCerdas = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
      // SurveyReferensiService.surveyReferensiGetData().then(response=>{
      //   this.asn_cerdas = response.data.asn_cerdas
      //   this.showDataASNCerdas = true
      // })
    },

    updateTable(){
      this.pegawai=[]
    },

    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    addRow(){
      this.loadingBtn.add = true
      this.dataPegawaiBaru = []
      //reviewer check if its a PLT or not
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      let params_nip = []
      this.modelListPegawaiSKPD.forEach(element => {
        this.no += 1
        var my_object = {
          foto: element.foto,
          nama: element.peg_nama,
          nip: element.peg_nip,
          jabatan: element.jabatan,
          no:this.no,
          rating: 0,
          disabled: true
        }
        params_nip.push(element.peg_nip)
        this.dataPegawaiBaru.push(my_object)
      });

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_add_reviewer = base_url_api + 'v1/add-reviewer-asn-cerdas-berprestasi'
      var date = new Date()
      var bulan = date.getMonth()
      axios.post(url_add_reviewer, {
        nip: params_nip,
        nip_reviewer: current.username,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          this.dataPegawaiBaru = []
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.add = false
        this.anggota = this.dataPegawaiBaru
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.add = false
        this.dataPegawaiBaru = []
        this.anggota = this.dataPegawaiBaru
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getDataPegawaiSKPD(kolok){
      // let kode_skpd = local.getLocal("kolok") ? local.getLocal("kolok") : this.$store.getters["user/data"].data_asn.kolok
      var kode_skpd = kolok ? kolok : local.getLocal("kolok")
      if(kode_skpd == null){
        kode_skpd = this.$store.getters["user/data"].data_asn["kolok"]
      }
      this.listPegawaiSKPD = []
      this.modelListPegawaiSKPD = []
      // SKPService.getPegawaiSKPD(kode_skpd).then(response=>{
      //   response.data.data.forEach(element => {
      //     element.foto = this.getImg(element.nip, element.peg_foto)
      //   });
      //   this.listPegawaiSKPD = response.data.data
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-pegawai-skpd-req-post'
      axios.post(url_get_data, {
        nip: current.username, kode_skpd: kode_skpd
      }).then(response => {
        response.data.data.forEach(element => {
          element.foto = this.getImg(element.nip, element.peg_foto)
        });
        this.listPegawaiSKPD = response.data.data
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    openDialog(nip, idx){
      this.loadingEl = idx
      var date = new Date()
      var bulan = date.getMonth()
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/ulas-survey-referensi-asn-cerdas'
      axios.post(url_get_data, {
        nip: current.username,
        nip_bawahan: nip,
        bulan: (bulan+1),
      }).then(response => {
        console.log(response.data)
        this.dataPegawai = []
        this.dataPegawai = response.data
        this.dialogSurveyReferensiASNCerdas=true
      }).finally(()=>{
          this.loadingEl = -1
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
          this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
      // SurveyReferensiService.surveyReferensiUlasNIP(nip, bulan).then(response => {
      //   console.log(response.data)
      //   this.dataPegawai = []
      //   this.dataPegawai = response.data
      //   this.dialog=true
      // }).finally(() => {
      //   this.loadingEl = -1
      // })
    },

    getDataReviewerSurveyReferensiASN(){
      // this.form.check = false
      // var bulan = 8
      this.countPegawai = 0
      this.dataPegawaiBaru = []
      var date = new Date()
      var bulan = date.getMonth()
      //reviewer check if its a PLT or not
      this.loadingBtn.dataPegawai = true
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-data-reviewer-survey-referensi-asn-cerdas'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: (bulan+1),
      }).then(response => {
        let res = response.data
        if (res.success){
          var cek = false
          if(res.data.length > 0){
            res.data.forEach(e => {
              // cek = e.rating == null ? true : false
              if(e.nomor != null && e.rating == null){
                cek = true
              }
            })
          }
          if(res.data.length == 0){
            cek = true
          }
          if(cek == false){
            this.belum_memilih = false
            this.showDataASNCerdas = true
          }else{
            this.belum_memilih = true
            this.asn_cerdas = []
            this.showDataASNCerdas = false
            store.commit('snackbar/setSnack',{message: 'Maaf anda belum memilih dan menilai rating kandidat ASN teladan, silahkan pilih dahulu. Terima kasih!', color:'warning'})
          }
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.dataPegawai = false
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingBtn.dataPegawai = false
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    // getDataReviewerSurveyReferensiASN(){
    //   // this.form.check = false
    //   // var bulan = 8
    //   this.dataPegawaiBaru = []
    //   var date = new Date()
    //   var bulan = date.getMonth()
    //   //reviewer check if its a PLT or not
    //   this.loadingBtn.dataPegawai = true
    //   let current = store.state.user.current
    //   let user = store.state.user
    //   if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas")){
    //     current.username = _g.overrideWithPLT(user.current.role)
    //   }else{
    //     current.username = user.current.username
    //   }

    //   var base_url_api = _g.getBaseURLAPIERK(current.year)
    //   const url_get_data = base_url_api + 'v1/get-data-reviewer-survey-referensi-asn-cerdas'
    //   axios.post(url_get_data, {
    //     nip: current.username,
    //     bulan: bulan,
    //   }).then(response => {
    //     let res = response.data
    //     if (res.success){
    //       res.data.forEach(element => {
    //         this.no += 1
    //         var my_object = {
    //           foto: element.foto,
    //           nama: element.peg_nama,
    //           nip: element.peg_nip,
    //           jabatan: element.jabatan,
    //           no:this.no,
    //           rating: element.rating == null ? 0 : element.rating,
    //           disabled: element.submit_survey_ref == false || element.submit_survey_ref == null || element.remedial == true ? false : true
    //         }
    //         this.dataPegawaiBaru.push(my_object)
    //       });
    //       this.anggota = this.dataPegawaiBaru
    //       store.commit('snackbar/setSnack',{message: this.anggota.length == 0 ? ' Data kandidat masih kosong, silahkan tambahkan dahulu.' : res.message, color: this.anggota.length == 0 ? 'warning' : 'success'})
    //     } else {
    //       store.commit('snackbar/setSnack',{message: res.message, color:res.response})
    //     }
    //   }).finally(()=>{
    //     this.loadingBtn.dataPegawai = false
    //   }).catch(err => {
    //     var error = err.message ? err.message : err.response.data.message
    //     this.loadingBtn.dataPegawai = false
    //     store.commit('snackbar/setSnack',{message: error, color:'error'})
    //   })
    // },

    storeRatingAndReviewer(peg, i){
      this.anggota[i].disabled = false

      //reviewer check if its a PLT or not
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_store_rating = base_url_api + 'v1/store-rating-asn-cerdas-berprestasi'
      var date = new Date()
      var bulan = date.getMonth()
      axios.post(url_store_rating, {
        nip: peg.nip,
        nip_reviewer: current.username,
        bulan: (bulan+1),
        rating: peg.rating
      }).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          this.dataPegawaiBaru = []
          store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        }
      }).finally(()=>{
        this.loadingBtn.add = false
        this.anggota = this.dataPegawaiBaru
      }).catch(err => {
        var error = err.response.data.message
        this.loadingBtn.add = false
        this.dataPegawaiBaru = []
        this.anggota = this.dataPegawaiBaru
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
